import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import { Jumbotron } from "react-bootstrap"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

// const Hero = styled(Jumbotron)`
//   text-align: center;
//   margin-bottom: 0;
//   border-radius: 0;
// `

const ContactUs = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0;
`

const SecondPage = () => (
  <Layout removeContainer>
    <SEO title='Contact Us' />
    {/* 
    <Hero className='bg-gradient'>
      <h1 className='text-white'>Get In Touch</h1>
    </Hero> */}

    <ContactUs>
      <iframe
        title='google-form'
        css={css`
          width: 100%;
          min-height: 140vh;
          @media screen and (max-width: 400px) {
            min-height: 170vh;
          }
        `}
        src='https://docs.google.com/forms/d/e/1FAIpQLSd6GMGTo4ayNG2sbDnIXroG903mnhj-Anf8yJktVLjJGWj9kw/viewform?embedded=true'
        frameborder='0'
        marginheight='0'
        marginwidth='0'
      >
        Loading…
      </iframe>
    </ContactUs>
  </Layout>
)

export default SecondPage
